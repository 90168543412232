<template src="./RecoveryAtIdD.html"></template>

<script>
import firstAdmissionService from '@/services/FirstAdmission';
export default {
  name: 'recovery-at-id-d',
  data () {
    return {
      courses: null,
      selectedCourse: null
    }
  },
  created() {
    firstAdmissionService.postEmailToValidate()
      .then(response => {
        this.courses = response.securityCourses
      })
      .catch((e) => {
        console.error('error', e)
      })
  },
  methods: {
    validateCourse() {
      if (this.selectedCourse === null) {
        this.$router.push({path: `/recuperatiderror`})
      } else {
      firstAdmissionService.postCourseToValidate(this.selectedCourse)
        .then(response => {
          if (response.curso === false) {
            this.$router.push({path: '/recuperatiderror'})
          } else {
            this.$router.push({path: '/recuperatide'})
          }
        })
        .catch((e) => {
          console.error('error', e)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./RecoveryAtIdD.scss">

</style>
